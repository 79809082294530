@import url('rsuite/dist/rsuite.css');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn_mng {
  color: #000;
  font-size: 16px;
  background-color: #f2f2f2;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}
.global_main {
  padding-left: 200px;
  padding-top: 75px;
  width: 100%;
  height: 100%;
}
.global_header {
  border-bottom: 1px solid black;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.rdt_Table {
  border: 1px solid gray;
}
.rdt_TableCol {
  border-left: 1px solid gray;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
}
.rdt_TableCell {
  border-left: 1px solid gray;
  justify-content: center;
  font-size: 13px;
}
.signatureCanvas {
  width: 100%;
  height: 100%;
}
.reservation-count-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.reservation-count-circle {
  color: black;
  border-radius: 50%;
  border: 1px solid black;
  width: 14px;
  height: 14px;
  font-size:9px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-right:-7px;
}
